import { HttpClient } from "@angular/common/http";
import { Injectable, Signal, signal, inject } from "@angular/core";
import { Observable, debounceTime, map, take, tap } from "rxjs";
import { IApiResponse } from "src/app/core/model/api-reponse";
import { BaseInfoServerReponse } from "../model/server-reposnse/base-info-server-reposnse";
import { CGPayMapper } from "../mapper/cg-pay-mapper";
import { BaseInfoModel } from "../model/client-model/base-info-model";
import { AssetsModel, IAvailableNetworks } from "../model/client-model/assets-model";
import { NetworksModel } from "../model/client-model/networks-model";
import { AssetsServerReponse } from "../model/server-reposnse/assets.server_reponse";
import { AssetsMapperResult } from "../model/client-model/mappet-result-model";
import { GenerateAddressServerReponse } from "../model/server-reposnse/generate_address_server_response";
import { GenerateAddress } from "../model/client-model/generate-address-model";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class CGPayService {
    private http = inject(HttpClient);


    assets = signal<AssetsModel[]>([]);
    retrunUrlSig = signal<string>('');
    // networks = signal<NetworksModel[]>([]);
    currencyNetworks = signal<IAvailableNetworks[]>([]);
    selectedAssets = signal<AssetsModel>({
        id: 0,
        availableNetworks: [],
        code: '0',
        isDefault: false,
        name: ''
    });
    generateAddressSig = signal<GenerateAddress>({
        cryptoAddress: '',
        memoRegex: '',
        memoType: ''
    });

    token: string = '';

    baseInfo(): Observable<BaseInfoModel> {

        return this.http.get<IApiResponse<BaseInfoServerReponse>>
            (`${environment.api}/ipg/b2p/crypto/${this.token}/base-info`)
            .pipe(
                map(res => CGPayMapper.baseInfo(res.data))
            );
    }

    getAssets(): Observable<AssetsMapperResult> {
        return this.http.get<IApiResponse<AssetsServerReponse>>(`${environment.api}/ipg/b2p/crypto/${this.token}/assets`)
            .pipe(
                take(1),
                map(res => CGPayMapper.assets(res.data)),
                tap((data: AssetsMapperResult) => {
                    this.assets.update(() => data.currencies);
                })
            );
    }

    selectCurrencyNetworks(currenctId?: number): void {
        if (!currenctId) {
            return;
        }

        const selectedNetworks = this.assets().find(x => x.id === currenctId);

        if (selectedNetworks) {
            this.currencyNetworks.set(selectedNetworks.availableNetworks);
            this.selectedAssets.set(selectedNetworks)
        }
    }

    generateAddress(currencyId: number, networkId: number): Observable<GenerateAddress> {
        return this.http.put<IApiResponse<GenerateAddressServerReponse>>
            (`${environment.api}/ipg/b2p/crypto/${this.token}/address`, {
                data: {
                    currencyId: currencyId,
                    networkId: networkId
                }
            })
            .pipe(
                take(1),
                map(res => CGPayMapper.generateAddress(res.data)),
                tap((data: GenerateAddress) => {
                    this.generateAddressSig.set(data);
                })
            );
    }


}